<template>
  <section class="containerHighlightedBox containerHighlightedBox__background" ref="containerHighlightedBox"></section>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
export default {
  name: 'highlightedBox',
  props: ['queryElementToHighLight'],
  emits: ['renderCompleted'],
  setup (props, { emit }) {
    const cloneReference = ref(null)
    const parent = ref(null)
    const containerHighlightedBox = ref(null)

    onMounted(() => {
      setUpHighlightedBox()
    })

    onUnmounted(() => {
      deleteClone()
    })

    /**
     * @description Clones the component and sets up the component and notifies it has been already rendered.
     */
    function setUpHighlightedBox () {
      parent.value = containerHighlightedBox.value.parentNode
      cloneComponent()
      changeZIndexHighlightedElement('add')
      emit('renderCompleted')
    }

    /**
     * @description Creates a clone of the highlight component to be used as a mask and avoid any click on it.
     */
    function cloneComponent () {
      cloneReference.value = containerHighlightedBox.value.cloneNode(true)
      cloneReference.value.classList.add('containerHighlightedBox__mask')
      cloneReference.value.addEventListener('click', clickHandler)
      parent.value.appendChild(cloneReference.value)
    }

    /**
     * @description Manages the click event to avoid it reaches other components.
     * @param {event} event launched by the user.
     */
    function clickHandler (event) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    /**
     * @description Changes the value of z-index of element to be highligthed to show it over the overlay.
     * @param {functionToCall} string with the name of the function to 'add' or 'remove' the class.
     */
    function changeZIndexHighlightedElement (functionToCall) {
      parent.value.querySelectorAll(props.queryElementToHighLight).forEach(element => {
        element.classList[functionToCall]('showOverTheOverlay')
      })
    }

    /**
     * @description Removes the clone when the component dissapears.
     */
    function deleteClone () {
      cloneReference.value.parentNode.removeChild(cloneReference.value)
      changeZIndexHighlightedElement('remove')
    }

    return {
      cloneReference,
      parent,
      containerHighlightedBox
    }
  }
}
</script>
