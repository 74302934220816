<template>
  <section class="containerSimilarAssets" :class="[stylesFrom, {'preview--openRelated' : isRelatedVisible}]">
    <p class="containerSimilarAssets__headerTitle" @click="toggleRelated">
      <span>RELATED</span>
    </p>
    <section class="containerSimilarAssets__containerCard">
      <a v-for="(item, index) in similarAssets" :key="index"
        class="containerSimilarAssets__containerCard--card pointer"
        :href="`${path}${item.id}`"
        @click="goToRelated(`${path}${item.id}`)"
      >
        <div class="containerSimilarAssets__containerCard--card-image">
          <img :src="item.thumbnail_url" class="image"/>
        </div>
        <div class="containerTitle">
          <p class="containerTitle__label">{{item.title}}</p>
        </div>
      </a>
    </section>
  </section>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'similarAssets',
  props: {
    articleId: {
      type: String,
      required: true
    },
    stylesFrom: {
      type: String,
      required: false,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const store = useStore()
    const similarAssets = ref([])
    const isRelatedVisible = ref(false)

    onMounted(() => {
      getSimilarThumbnails()
    })

    /**
    * @description Makes a call to store's action to retrieve similar thumbnails information.
    */
    function getSimilarThumbnails () {
      similarAssets.value = null
      store.dispatch({
        type: 'getSimilarThumbnails',
        id: props.articleId
      }).then((response) => {
        similarAssets.value = response.filter((value) => {
          return value
        })
      })
    }

    /**
     * @description Toggle stylesFrom value to show or hide related items
     */
    function toggleRelated () {
      if (props.stylesFrom === 'preview') isRelatedVisible.value = !isRelatedVisible.value
    }

    /**
     * @description Go to related item
     * @param relatedUrl Path to redirect
     */
    function goToRelated (relatedUrl) {
      window.location = relatedUrl
    }

    return {
      store,
      similarAssets,
      isRelatedVisible,
      toggleRelated,
      goToRelated
    }
  }
}
</script>
