<template>
  <section class="reactionsBox">
    <p v-if="store.state.reactions[itemId].likes.isVisible && !likesDetail" class="reactionsBox__item like"
      :class="{'disable': store.state.reactions[itemId].likes.disabled}" @click.stop.prevent="likeToggle">
      <span v-if="store.state.reactions[itemId].likes.userGaveLike" class="grey-icon-heart-fill"/>
      <span v-else class="grey-icon-heart"/>
      <span class="count">{{store.state.reactions[itemId].likes.count}}</span>
    </p>

    <p v-if="store.state.reactions[itemId].likes.isVisible && likesDetail" class="reactionsBox__item likesDetail"
        :class="{'disable': store.state.reactions[itemId].likes.disabled}"
        @contextmenu.prevent>
      <span v-if="store.state.reactions[itemId].likes.userGaveLike" class="grey-icon-heart-fill" @click.stop.prevent="likeToggle"/>
      <span v-else class="grey-icon-heart" @click.stop.prevent="likeToggle"/>
      <span title="Likes Review" class="count" :class="{'enabled': allowOpenLikesModal}" @click.stop.prevent="openLikesModal">{{store.state.reactions[itemId].likes.count}}</span>
    </p>

    <p v-if="store.state.reactions[itemId].views.isVisible" class="reactionsBox__item">
      <span class="grey-icon-views" />
      <span class="count">{{store.state.reactions[itemId].views.count}}</span>
    </p>

    <p v-if="store.state.reactions[itemId].comments.isVisible" class="reactionsBox__item"
      :class="{'disable': store.state.reactions[itemId].comments.disabled}">
      <span class="grey-icon-comments" />
      <span class="count">{{store.state.reactions[itemId].comments.count}}</span>
    </p>
  </section>
</template>

<script>
import { nextTick, computed, ref } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'reactionBox',
  props: {
    itemId: {
      type: String
    },
    likesDetail: {
      type: Boolean,
      default: false
    }
  },
  emits: ['openLikesModal'],
  setup (props, { emit }) {
    const store = useStore()
    const likeDisabled = ref(false)

    /**
     * @description Emit open likes modal
     */
    const allowOpenLikesModal = computed(() => {
      const reactionLikes = store.state.reactions[props.itemId].likes
      return reactionLikes.count > 0 && !reactionLikes.disabled
    })

    /**
     * @description Dispatch action to like an item
     */
    function likeToggle () {
      if (likeDisabled.value) {
        return
      }
      if (!store.state.reactions[props.itemId].likes.disabled) {
        likeDisabled.value = true
        store.dispatch({
          type: 'likeToggle',
          id: props.itemId,
          isLiked: store.state.reactions[props.itemId].likes.userGaveLike
        }).then(() => {
          nextTick(() => {
            likeDisabled.value = false
          })
        })
      }
    }

    /**
     * @description Emit open likes modal
     */
    function openLikesModal () {
      emit('openLikesModal')
    }

    return {
      store,
      likeDisabled,
      allowOpenLikesModal,
      likeToggle,
      openLikesModal
    }
  }
}
</script>
