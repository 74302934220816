<template>
  <section class="containerOptionsCard">
    <highlightedBox class="containerOptionsCard__tour"
                    queryElementToHighLight=".containerOptionsCard__showMoreIcon"
                    @renderCompleted="startTour"
                    v-if="store.state.activeTours.GSV_threeDotsCardMenu.isPending && index === 0"/>
    <span class="grey-icon-show-more containerOptionsCard__showMoreIcon" @click.stop.prevent="showOptionsCard()" v-if="optionsOftheSource.length" @contextmenu.prevent></span>
    <transition enter-active-class="menuAnimationIn" leave-active-class="menuAnimationOut">
      <section class="containerOptionsCard__containerOptions" v-if="optionsLoaded && validateOptionMenuShown" v-click-away="close">
        <ul class="containerOptionsCard__containerOptions--options">
          <a v-for="(item, index) in options" :key="index"
            :href="optionCardHref(item)"
            class="item"
            @click.prevent.stop="manageEvent($event, item.action)"
            @contextmenu="manageOptionRightClick($event, item)"
          >
            <span :class="item.icon" class="item__icon"></span>
            <span class="item__label">{{item.name}}</span>
          </a>
        </ul>
      </section>
    </transition>
  </section>
</template>

<script>
import { onMounted, onUnmounted, computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import messages from './optionsCard.json'
import highlightedBox from '@/components/highlightedBox/highlightedBox'

export default {
  name: 'optionsCard',
  components: {
    highlightedBox
  },
  props: {
    id: {
      type: String,
      required: true
    },
    source: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  emits: ['openAddToPortfolioModal', 'openCollectionsModal', 'openDeleteModal'],
  setup (props, { emit }) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    let thisVariable = null
    const options = ref([])
    const openOptions = ref(false)
    const optionsLoaded = ref(false)
    const optionsOftheSource = ref([])
    const actionsNotCached = ref([])
    const actionsCached = ref([])
    const showAddToMyPortfolio = ref(false)
    const showRemoveFromMyPortfolio = ref(false)
    const isMyItem = ref(false)
    const isMyIntelligenceItem = ref(false)

    /**
     * @description Show condition depending if it is mobile or desktop
     */
    const validateOptionMenuShown = computed(() => {
      return !navigator.userAgent.match(/Android|iPhone|iPad/i) ? store.state.threeDotsMenu === props.id : openOptions.value
    })

    /**
     * @description Generate option card href attribute
     * @param item item of the option card
     */
    const optionCardHref = computed(() => (item) => {
      return item.name === 'Edit' ? `/uploadFile/${props.id}` : ''
    })

    onMounted(() => {
      setUpOptionsCard()
    })

    onUnmounted(() => {
      if (store.state.threeDotsMenu === props.id) {
        store.commit({
          type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
          item: ''
        })
      }
    })

    /**
     * @description Makes a list of the posibble options to show according to the source of the item. Also creates an
     * array with all the methods to be called one the user opens the menu.
     */
    function setUpOptionsCard () {
      optionsOftheSource.value = messages.options.filter(item => {
        return item.sources.some(source => source.toLowerCase() === props.source.toLowerCase())
      })

      optionsOftheSource.value = optionsOftheSource.value.filter((item) => {
        return !item.permissions || !item.permissions.some(permission => {
          return !store.getters.actionCanBePerformed(permission.ability, permission.item)
        })
      })

      const actionsCachedTemp = new Set()
      const actionsNotCachedTemp = new Set()
      optionsOftheSource.value.forEach(item => {
        if (item.validation) {
          if (item.useCache) {
            actionsCachedTemp.add(item.validation)
          } else {
            actionsNotCachedTemp.add(item.validation)
          }
        }
      })
      actionsCached.value = [...actionsCachedTemp]
      actionsNotCached.value = [...actionsNotCachedTemp]
    }

    /**
     * @description Launches all the actions neeed to know which options will be shown or not.
     * @param {launchOnlyNotCached} boolean to indicate if all the requests should be launched or only not cached ones.
     */
    function launchRequest (launchOnlyNotCached = false) {
      const actionsToCall = launchOnlyNotCached ? actionsNotCached.value : actionsNotCached.value.concat(actionsCached.value)
      const promises = actionsToCall.map(action => thisVariable[action]())

      return Promise.all(promises).then(() => {
        filterOptions()
        optionsLoaded.value = true
      })
    }

    /**
     * @description According to the flags got from API filter those visible options.
     */
    function filterOptions () {
      options.value = optionsOftheSource.value.filter(option => {
        return !option.flagToShowOption || thisVariable[option.flagToShowOption]
      })
    }

    /**
     * @description Makes a dispatch on the store to get the flag about if the user can edit/detele the current item.
     */
    function canDeleteWorkItem () {
      return store.dispatch({
        type: 'work/canDeleteWorkItem',
        id: props.id
      }).then((result) => {
        isMyItem.value = result
        return result
      })
    }

    /**
     * @description Makes a dispatch on the store to get the flag about if the user can edit/detele the current item.
     */
    function canDeleteIntelligenceItem () {
      return store.dispatch({
        type: 'canDeleteIntelligenceItem',
        id: props.id
      }).then((result) => {
        isMyIntelligenceItem.value = result
        return result
      })
    }

    /**
     * @description Maskes adispatch on the store to get the flag about if the item is on my portfolio.
     */
    function isItemOnMyPortfolio () {
      return store.dispatch({
        type: 'isItemOnMyPortfolio',
        id: props.id
      }).then((result) => {
        showRemoveFromMyPortfolio.value = result
        showAddToMyPortfolio.value = !showRemoveFromMyPortfolio.value
        return result
      })
    }

    /**
     * @description: Open or close options when user click in dots.
     */
    function showOptionsCard () {
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: props.id
      })
      thisVariable = this
      launchRequest(optionsLoaded.value).then(() => (openOptions.value = !openOptions.value))
    }

    /**
     * @description Launches an action to add the item to portfolio.
     */
    function addItemToPortfolio () {
      addOrRemovePortfolioItem()
    }

    /**
     * @description Launches an action to remove the item to portfolio.
     */
    function removeItemFromPortfolio () {
      addOrRemovePortfolioItem(false)
    }

    /**
     * @description Launches a dispatch to add/remove the item to/from my portfolio.
     * @param {isAdding} boolean to know if operation will be add or delete.
     */
    function addOrRemovePortfolioItem (isAdding = true) {
      const functionToDispatch = isAdding ? 'addItemToPortfolio' : 'deleteItemFromPortfolio'
      const modeOfModal = isAdding ? 'add' : 'remove'
      const showAddingOption = !isAdding

      close()
      store.dispatch({
        type: functionToDispatch,
        id: props.id
      }).then(() => {
        emit('openAddToPortfolioModal', modeOfModal)
        showAddToMyPortfolio.value = showAddingOption
        showRemoveFromMyPortfolio.value = !showAddToMyPortfolio.value
        filterOptions()
      })
    }

    /**
     * @description Close options when user clicks outside of component.
     */
    function close () {
      openOptions.value = false
      store.commit({
        type: 'SET_THREE_DOTS_MENU_STATE_BY_ITEM',
        item: ''
      })
    }

    /**
     * @description Calls a method of the current context and passes the event that called it.
     * @param event DOM event.
     * @param methodName string with the name of the method.
     */
    function manageEvent (event, methodName) {
      this[methodName](event)
    }

    /**
     * @description Open collections modal throught emit and call service to get list of collections.
     * @param event of DOM
     */
    function openModal (event) {
      close()
      store.dispatch({
        type: 'getCollections',
        ids: [props.id]
      }).then(
        (response) => {
          emit('openCollectionsModal', props.id, true)
        }
      )
    }

    /**
     * @description open the delete item modal confirmation.
     */
    function openModalDeleteItem () {
      close()
      emit('openDeleteModal')
    }

    /**
     * @description Redirects the user to page of edition of an item.
     * @param {event} event raised by the user.
     */
    function goToEditionPage (event) {
      event.stopPropagation()
      event.preventDefault()
      window.open(`${process.env.VUE_APP_SEARCH_HOST}${window.location.host}/uploadFile/${props.id}`, '_self')
    }

    /**
     * @description Commits an action to start the three dots menu tour.
     */
    function startTour () {
      setTimeout(() => {
        store.commit({
          type: 'TOGGLE_ACTIVE_TOUR',
          tourName: 'GSV_threeDotsCardMenu',
          property: 'isRenderReady',
          value: true
        })
      }, 350)
    }

    /**
     * @description Manage right click event
     * @param event DOM event
     * @param item option card clicked
     */
    function manageOptionRightClick (event, item) {
      if (item.name !== 'Edit') {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    return {
      store,
      route,
      router,
      thisVariable,
      options,
      openOptions,
      optionsLoaded,
      optionsOftheSource,
      actionsNotCached,
      actionsCached,
      showAddToMyPortfolio,
      showRemoveFromMyPortfolio,
      isMyItem,
      isMyIntelligenceItem,
      validateOptionMenuShown,
      canDeleteWorkItem,
      canDeleteIntelligenceItem,
      isItemOnMyPortfolio,
      showOptionsCard,
      addItemToPortfolio,
      removeItemFromPortfolio,
      close,
      manageEvent,
      openModal,
      openModalDeleteItem,
      goToEditionPage,
      startTour,
      optionCardHref,
      manageOptionRightClick
    }
  }
}
</script>
