<template>
  <genericModalLogic title="Delete Item"
                     class="deleteItemModal"
                     v-if="isModalVisible"
                     @closeModal="close">
    <p class="deleteItemModal__message">
      <span class="deleteItemModal__message--message">
        Are you sure you want to delete this item?
      </span>
      <span class="deleteItemModal__message--message">This may take a while.</span>
    </p>
    <section class="deleteItemModal__buttons">
      <button class="deleteItemModal__buttons--cancelButton" @click.stop="close" type="button">CANCEL</button>
      <button class="deleteItemModal__buttons--deletedButton" type="button" @click.stop="deleteItem">DELETE</button>
    </section>
  </genericModalLogic>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import genericModalLogic from '../genericModalLogic/genericModalLogic'

export default {
  props: {
    detailPage: {
      type: Boolean,
      default: false
    },
    source: {
      required: true,
      default: ''
    }
  },
  components: {
    genericModalLogic
  },
  emits: ['closeSideBar', 'dispatchSearch', 'showDeleteErrorToast'],
  setup (props, { emit }) {
    const store = useStore()
    const router = useRouter()
    const itemIdToDelete = ref(null)
    const isModalVisible = ref(false)

    /**
     * @description Opens the modal.
     * @param itemId id of item to delete
     */
    function open (itemId) {
      itemIdToDelete.value = itemId
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     * @param event of DOM.
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    /**
     * @description Delete the selected item.
     * @param event of DOM.
     */
    function deleteItem (event) {
      event.preventDefault()
      store.dispatch({
        type: props.source.toLowerCase() === 'work' ? 'work/deleteWorkItem' : 'deleteIntellItem',
        id: itemIdToDelete.value
      }).then((response) => {
        if (response.success) {
          if (props.detailPage) {
            setTimeout(() => {
              isModalVisible.value = false
              router.push({ name: props.source.toLowerCase() === 'work' ? 'resultsWork' : 'resultsIntelligence' })
            }, 1000)
          } else {
            setTimeout(() => {
              isModalVisible.value = false
              emit('dispatchSearch')
            }, 1000)
          }
        } else {
          emit('showDeleteErrorToast')
        }
      }).catch((error) => {
        console.error(error)
        emit('showDeleteErrorToast')
      })
    }

    return {
      store,
      router,
      itemIdToDelete,
      isModalVisible,
      open,
      close,
      deleteItem
    }
  }
}
</script>
