<template>
  <genericModalLogic :title="configuration.title"
                     class="containerPortfolioAddedModal"
                     v-if="isModalVisible"
                     @closeModal="close">
    <p class="containerPortfolioAddedModal__message">
      <span class="containerPortfolioAddedModal__message--message">{{ configuration.message }}</span>
      <button @click.stop="close" class="containerPortfolioAddedModal__message--button">OK</button>
    </p>
  </genericModalLogic>
</template>

<script>
import { ref } from 'vue'
import genericModalLogic from '../genericModalLogic/genericModalLogic'
import labels from './addItemToPortfolioModal.json'

export default {
  components: {
    genericModalLogic
  },
  setup (props) {
    const isModalVisible = ref(false)
    const configuration = ref(labels.add)

    /**
     * @description Opens the modal.
     * @param {mode} string to indicate if message will be about removing or adding an item.
     */
    function open (mode) {
      configuration.value = labels[mode]
      isModalVisible.value = true
    }

    /**
     * @description Closes the modal.
     */
    function close (event) {
      event.preventDefault()
      isModalVisible.value = false
    }

    return {
      isModalVisible,
      configuration,
      open,
      close
    }
  }
}
</script>
